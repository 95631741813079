import "./App.scss";
import Form from "./components/Form/Form";
import AlbumsList from "./components/AlbumList/AlbumList";
import useLocalStorage from "./hooks/useLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faList } from "@fortawesome/free-solid-svg-icons";

function App() {
	const [albums, setAlbums] = useLocalStorage("data", []);
	const [nextId, setNextId] = useLocalStorage("ID", 1);
	const [view, setView] = useLocalStorage("viewType", "list");

	const addAlbum = (albumTitle) => {
		let timestamp = new Date().toLocaleDateString("pl");

		setNextId((prevState) => prevState + 1);

		setAlbums([
			...albums,
			{
				id: nextId,
				title: albumTitle,
				date: timestamp,
			},
		]);
	};

	const removeAlbum = (albumId) => {
		setNextId(nextId);

		setAlbums(
			albums.filter(function (album) {
				return album.id !== albumId;
			})
		);
	};

	const addToFav = (albumId) => {
		setAlbums(
			albums.map((obj) => {
				if (obj.id === albumId) {
					return { ...obj, fav: !obj.fav };
				}
				return obj;
			})
		);
	};

	const sortTable = (handler, order) => {
		let sortedTable = [];
		if (handler === "id") {
			sortedTable = [...albums].sort((a, b) =>
				a.id.toString().localeCompare(b.id.toString(), "en", { numeric: true })
			);
		}
		if (handler === "title") {
			sortedTable = [...albums].sort((a, b) => a.title.localeCompare(b.title));
		}
		if (handler === "date") {
			sortedTable = [...albums].sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});
		}
		if (order === true) {
			sortedTable.reverse();
		}

		setAlbums(sortedTable);
	};

	return (
		<div className="app">
			<div className="app__container">
				<header className="app__header">
					<Form addAlbum={addAlbum} />
					<div className="app__buttonsContainer">
						<button onClick={() => setView("list")}>
							<FontAwesomeIcon icon={faList} />
						</button>
						<button onClick={() => setView("grid")}>
							<FontAwesomeIcon icon={faSquare} />
						</button>
					</div>
				</header>
				<AlbumsList
					view={view}
					albums={albums}
					removeAlbum={removeAlbum}
					addToFav={addToFav}
					sortTable={sortTable}
				/>
			</div>
		</div>
	);
}

export default App;
