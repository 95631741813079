import React from "react";
import { useState } from "react";
import "./Form.scss";

function Form({ addAlbum }) {
	const [albumName, setAlbumName] = useState("");

	function handleSubmit(e) {
		e.preventDefault();

		if (albumName === "") return alert("Field cannot be empty");

		addAlbum(albumName);
		setAlbumName("");
	}

	return (
		<div className="formContainer">
			<form>
				<input
					type="text"
					name="title"
					value={albumName}
					onChange={(e) => setAlbumName(e.target.value)}
					placeholder="Album title"
					required
				></input>
				<button type="submit" onClick={handleSubmit}>
					Add to list
				</button>
			</form>
		</div>
	);
}

export default Form;
