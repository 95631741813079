import React, { useState } from "react";
import AlbumListItem from "./AlbumListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import "./AlbumList.scss";

function AlbumList(props) {
	const [order, setOrder] = useState(false);

	const handleSort = (handler) => {
		setOrder(!order);

		props.sortTable(handler, order);
	};

	return (
		<div className={`albums ${props.view}`}>
			<header className="albums__header">
				<p onClick={(e) => handleSort("id")}>
					ID <FontAwesomeIcon icon={faSort} />
				</p>
				<p onClick={() => handleSort("title")}>
					Title <FontAwesomeIcon icon={faSort} />
				</p>
				<p onClick={() => handleSort("date")}>
					Date added <FontAwesomeIcon icon={faSort} />
				</p>
			</header>
			<article className="albums__content">
				{props.albums.map(function (album) {
					return (
						<AlbumListItem
							key={album.id + album.title}
							id={album.id}
							title={album.title}
							date={album.date}
							fav={album.fav}
							removeAlbum={props.removeAlbum}
							addToFav={props.addToFav}
						/>
					);
				})}
				{props.albums.length === 0 && "Albums not found"}
			</article>
		</div>
	);
}

export default AlbumList;
