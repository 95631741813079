import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faTrash } from "@fortawesome/free-solid-svg-icons";

function AlbumListItem(props) {
	return (
		<div className="albums__item">
			<p>{props.id}</p>
			<p>{props.title}</p>
			<p>{props.date}</p>
			<div>
				<button onClick={(e) => props.addToFav(props.id)}>
					{!props.fav ? (
						<FontAwesomeIcon icon={faHeart} />
					) : (
						<FontAwesomeIcon icon={faHeart} inverse />
					)}
				</button>
				<button
					className="albums__remove"
					onClick={(e) => props.removeAlbum(props.id)}
				>
					<FontAwesomeIcon icon={faTrash} />
				</button>
			</div>
		</div>
	);
}

export default AlbumListItem;
